import React from "react";
import { Input } from "reactstrap";

import "./CurrencyInput.css";

function CurrencyInput(props) {
  return (
    <div className="input-icon input-icon-right">
      <Input
        type="text"
        inputMode="numeric"
        pattern="^\\$?(([1-9](\\d*|\\d{0,2}(,\\d{3})*))|0)(\\.\\d{1,2})?$"
        {...props}
      />
      <i>€</i>
    </div>
  );
}

export default CurrencyInput;
