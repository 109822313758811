import React from "react";
import { Badge } from "reactstrap";

export const FreeAccess = ({ user }) => {
  if (user && user.freeUse) {
    return <Badge color="warning">Acces Gratuit utilisé</Badge>;
  } else {
    return "";
  }
};
