import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  Col,
  Input,
  Container,
} from "reactstrap";
import { useNavigate } from "react-router-dom";

import { BsTrash } from "react-icons/bs";

import "./VenteLot.css";
import {
  prettyEuro,
  prettyPrixMetreCarre,
  getData,
  toastConfig,
  putData,
  arrondi,
} from "../../utils";
import { TypeBien, refTypeBien } from "./TypeBien";
import { TVA, refTVA } from "./TVA";
import Ventilation from "./Ventilation";
import PrixVente from "./PrixVente";
import FraisAgence from "./FraisAgence";
import PrixVenteMetreCarre from "./PrixVenteMetreCarre";
import PrixFAI from "./PrixFAI";
import Montant from "./Montant";

function VenteLot(props) {
  const [activeTab, setActiveTab] = useState("type");
  const [itemOrder, setItemOrder] = useState(0);
  const [surface, setSurface] = useState("");
  const [ventilation, setVentilation] = useState("");
  const [prixVente, setPrixVente] = useState(0);
  const [prixVenteMoyen, setPrixVenteMoyen] = useState(0);
  const [prixVenteHaut, setPrixVenteHaut] = useState(0);
  const [loyer, setLoyer] = useState(0);
  const [fraisAgence, setFraisAgence] = useState(0);
  const [fraisAgenceMoyen, setFraisAgenceMoyen] = useState(0);
  const [fraisAgenceHaut, setFraisAgenceHaut] = useState(0);
  const [typeBien, setTypeBien] = useState("");
  const [tva, setTVA] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    async function getUseEffectData() {
      try {
        const lot = await getData(
          `/v1/projets/${props.projet}/lots/${props.uniqueId}`
        );

        setItemOrder(lot.itemOrder);
        setTypeBien(lot.typeBien);
        setSurface(lot.surface);
        setVentilation(lot.ventilation);
        setFraisAgence(lot.fraisAgence ? lot.fraisAgence : 0);
        setFraisAgenceMoyen(lot.fraisAgenceMoyen ? lot.fraisAgenceMoyen : 0);
        setFraisAgenceHaut(lot.fraisAgenceHaut ? lot.fraisAgenceHaut : 0);
        setLoyer(lot.loyer);
        setPrixVente(lot.prixVente);
        setPrixVenteMoyen(lot.prixVenteMoyen);
        setPrixVenteHaut(lot.prixVenteHaut);
        setPrixVente(lot.prixVente);
        setPrixVenteMoyen(lot.prixVenteMoyen);
        setPrixVenteHaut(lot.prixVenteHaut);
        setTVA(lot.tva);
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate, props.projet, props.uniqueId]);

  const updateLot = async (body) => {
    try {
      await putData(`/v1/projets/${props.projet}/lots/${props.uniqueId}`, body);
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleModification = async (type, value) => {
    //console.log({ type, value });
    switch (type) {
      case "prixVente":
        await updateLot({ prixVente: arrondi(value, 10) });
        setPrixVente(arrondi(value, 10));
        break;
      case "loyer":
        await updateLot({ loyer: arrondi(value, 10) });
        setLoyer(arrondi(value, 10));
        break;
      case "prixVenteMoyen":
        await updateLot({ prixVenteMoyen: arrondi(value, 10) });
        setPrixVenteMoyen(arrondi(value, 10));
        break;
      case "prixVenteHaut":
        await updateLot({ prixVenteHaut: arrondi(value, 10) });
        setPrixVenteHaut(arrondi(value, 10));
        break;
      case "surface":
        await updateLot({ surface: arrondi(value, 10) });
        setSurface(arrondi(value, 10));
        break;
      case "ventilation":
        await updateLot({ ventilation: arrondi(value, 10) });
        setVentilation(value !== "" ? arrondi(value, 10) : "");
        break;
      case "fraisAgence":
        await updateLot({ fraisAgence: arrondi(value, 10) });
        setFraisAgence(arrondi(value, 10));
        break;
      case "fraisAgenceMoyen":
        await updateLot({ fraisAgenceMoyen: arrondi(value, 10) });
        setFraisAgenceMoyen(arrondi(value, 10));
        break;
      case "fraisAgenceHaut":
        await updateLot({ fraisAgenceHaut: arrondi(value, 10) });
        setFraisAgenceHaut(arrondi(value, 10));
        break;
      case "typeBien":
        await updateLot({ typeBien: arrondi(value, 10) });
        setTypeBien(value);
        break;
      case "tva":
        await updateLot({ tva: arrondi(value, 10) });
        setTVA(value);
        break;
      default:
        console.log(`Type inconnu ${type}`);
    }
  };

  const summary = (
    <Row>
      <Col xs="12" className="vente-lot-summary">
        <span className="mr-10">{refTypeBien[typeBien]}</span>
        <span className="mr-10">
          {surface && surface !== "" ? `${surface}m²` : ""}
        </span>
        <span className="mr-10">
          {prixVente && prixVente !== "" ? `${prettyEuro(prixVente)}` : ""}
        </span>
        <span className="mr-10">
          {fraisAgence && fraisAgence !== ""
            ? `${prettyEuro(fraisAgence)}`
            : ""}
        </span>
        <span className="mr-10">
          {fraisAgence !== "" && prixVente && prixVente !== ""
            ? `${prettyEuro(arrondi(fraisAgence, 10) + arrondi(prixVente, 10))}`
            : ""}
        </span>
        <span className="mr-10">
          {surface > 0 && fraisAgence !== "" && prixVente !== ""
            ? prettyPrixMetreCarre(
                arrondi((fraisAgence + prixVente) / surface, 10)
              )
            : ""}
        </span>
        <span className="mr-10">{tva >= 0 && refTVA[tva]}</span>
        <span className="mr-10">{loyer >= 0 && prettyEuro(loyer)}</span>
      </Col>
      {prixVenteMoyen && (
        <Col xs="12" className="vente-lot-summary">
          <span className="mr-10">{refTypeBien[typeBien]}</span>
          <span className="mr-10">
            {surface && surface !== "" ? `${surface}m²` : ""}
          </span>
          <span className="mr-10">
            {prixVenteMoyen && prixVenteMoyen !== ""
              ? `${prettyEuro(prixVenteMoyen)}`
              : ""}
          </span>
          <span className="mr-10">
            {fraisAgenceMoyen && fraisAgenceMoyen !== ""
              ? `${prettyEuro(fraisAgenceMoyen)}`
              : ""}
          </span>
          <span className="mr-10">
            {fraisAgenceMoyen !== "" && prixVenteMoyen && prixVenteMoyen !== ""
              ? `${prettyEuro(
                  arrondi(fraisAgenceMoyen, 10) + arrondi(prixVenteMoyen, 10)
                )}`
              : ""}
          </span>
          <span className="mr-10">
            {surface > 0 && fraisAgenceMoyen !== "" && prixVenteMoyen !== ""
              ? prettyPrixMetreCarre(
                  arrondi((fraisAgenceMoyen + prixVenteMoyen) / surface, 10)
                )
              : ""}
          </span>
          <span className="mr-10">{tva >= 0 && refTVA[tva]}</span>
          <span className="mr-10">{loyer >= 0 && prettyEuro(loyer)}</span>
        </Col>
      )}
      {prixVenteHaut && (
        <Col xs="12" className="vente-lot-summary">
          <span className="mr-10">{refTypeBien[typeBien]}</span>
          <span className="mr-10">
            {surface && surface !== "" ? `${surface}m²` : ""}
          </span>
          <span className="mr-10">
            {prixVenteHaut && prixVenteHaut !== ""
              ? `${prettyEuro(prixVenteHaut)}`
              : ""}
          </span>
          <span className="mr-10">
            {fraisAgenceHaut && fraisAgenceHaut !== ""
              ? `${prettyEuro(fraisAgenceHaut)}`
              : ""}
          </span>
          <span className="mr-10">
            {fraisAgenceHaut !== "" && prixVenteHaut && prixVenteHaut !== ""
              ? `${prettyEuro(
                  arrondi(fraisAgenceHaut, 10) + arrondi(prixVenteHaut, 10)
                )}`
              : ""}
          </span>
          <span className="mr-10">
            {surface > 0 && fraisAgenceHaut !== "" && prixVenteHaut !== ""
              ? prettyPrixMetreCarre(
                  arrondi((fraisAgenceHaut + prixVenteHaut) / surface, 10)
                )
              : ""}
          </span>
          <span className="mr-10">{tva >= 0 && refTVA[tva]}</span>
          <span className="mr-10">{loyer >= 0 && prettyEuro(loyer)}</span>
        </Col>
      )}
    </Row>
  );
  return (
    <div className="mb-25">
      <Container fluid>
        <Row>
          <Col xs={10}>
            <span className="vente-lot-nombre">{itemOrder}</span>
            <BsTrash
              color="red"
              size={20}
              className="pointer"
              onClick={props.removeLot}
            />
          </Col>
          <Col xs={2} className="vente-lot-trash"></Col>
        </Row>
        <Row className="pt-10">
          <Col xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "type" ? "active" : ""}
                  onClick={() => setActiveTab("type")}
                >
                  Type
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "surface" ? "active" : ""}
                  onClick={() => setActiveTab("surface")}
                >
                  Surface
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "prixvente" ? "active" : ""}
                  onClick={() => setActiveTab("prixvente")}
                >
                  Prix vente
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "fraisagence" ? "active" : ""}
                  onClick={() => setActiveTab("fraisagence")}
                >
                  Frais agence
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "prixfai" ? "active" : ""}
                  onClick={() => setActiveTab("prixfai")}
                >
                  Prix FAI
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "prixm2" ? "active" : ""}
                  onClick={() => setActiveTab("prixm2")}
                >
                  Prix €/m²
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "tva" ? "active" : ""}
                  onClick={() => setActiveTab("tva")}
                >
                  TVA
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "loyer" ? "active" : ""}
                  onClick={() => setActiveTab("loyer")}
                >
                  Loyer
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="type" className="vente-lot-tab">
                <Row>
                  <Col xs="12">
                    <TypeBien
                      value={typeBien}
                      onChange={(e) =>
                        handleModification("typeBien", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="surface" className="vente-lot-tab">
                <Row>
                  <Col xs="12">
                    <span style={{ display: "inline-flex" }}>
                      <div className="input-icon input-icon-right">
                        <Input
                          type="text"
                          value={surface ? surface : ""}
                          onChange={(e) => setSurface(e.target.value)}
                          onBlur={(e) =>
                            handleModification("surface", e.target.value)
                          }
                          style={{ width: "200px" }}
                        />
                        <i>m²</i>
                      </div>
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="prixvente" className="vente-lot-tab">
                <Row>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVente
                        label="Prix de vente (net) bas"
                        value={prixVente ? prixVente : ""}
                        onChange={(e) =>
                          handleModification("prixVente", e.target.value)
                        }
                      />
                      <Ventilation
                        ventilationclassname="ml-10"
                        value={ventilation ? ventilation : ""}
                        onChange={(e) =>
                          handleModification("ventilation", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVente
                        label="Moyen"
                        value={prixVenteMoyen ? prixVenteMoyen : ""}
                        onChange={(e) =>
                          handleModification("prixVenteMoyen", e.target.value)
                        }
                      />
                      <Ventilation
                        ventilationclassname="ml-10"
                        value={ventilation ? ventilation : ""}
                        onChange={(e) =>
                          handleModification("ventilation", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVente
                        label="Haut"
                        value={prixVenteHaut ? prixVenteHaut : ""}
                        onChange={(e) =>
                          handleModification("prixVenteHaut", e.target.value)
                        }
                      />
                      <Ventilation
                        ventilationclassname="ml-10"
                        value={ventilation ? ventilation : ""}
                        onChange={(e) =>
                          handleModification("ventilation", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="fraisagence" className="vente-lot-tab">
                <Row>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <FraisAgence
                        label="Frais d'agence bas"
                        value={fraisAgence}
                        onChange={(e) =>
                          handleModification("fraisAgence", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <FraisAgence
                        label="Moyen"
                        value={fraisAgenceMoyen}
                        onChange={(e) =>
                          handleModification("fraisAgenceMoyen", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <FraisAgence
                        label="Haut"
                        value={fraisAgenceHaut}
                        onChange={(e) =>
                          handleModification("fraisAgenceHaut", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="prixfai" className="vente-lot-tab">
                <Row>
                  <Col xs="12" md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixFAI
                        label="Prix de vente bas FAI"
                        value={
                          !isNaN(
                            arrondi(fraisAgence, 10) + arrondi(prixVente, 10)
                          )
                            ? arrondi(fraisAgence, 10) + arrondi(prixVente, 10)
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                  <Col xs="12" md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixFAI
                        label="Prix de vente moyen FAI"
                        value={
                          !isNaN(
                            arrondi(fraisAgenceMoyen, 10) +
                              arrondi(prixVenteMoyen, 10)
                          )
                            ? arrondi(fraisAgenceMoyen, 10) +
                              arrondi(prixVenteMoyen, 10)
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                  <Col xs="12" md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixFAI
                        label="Prix de vente haut FAI"
                        value={
                          !isNaN(
                            arrondi(fraisAgenceHaut, 10) +
                              arrondi(prixVenteHaut, 10)
                          )
                            ? arrondi(fraisAgenceHaut, 10) +
                              arrondi(prixVenteHaut, 10)
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="prixm2" className="vente-lot-tab">
                <Row>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVenteMetreCarre
                        label="Prix € / m² (Bas)"
                        value={
                          surface > 0
                            ? arrondi((fraisAgence + prixVente) / surface, 10)
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVenteMetreCarre
                        label="Prix € / m² (Moyen)"
                        value={
                          surface > 0
                            ? arrondi(
                                (fraisAgenceMoyen + prixVenteMoyen) / surface,
                                10
                              )
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                  <Col xs={12} md={4}>
                    <span style={{ display: "inline-flex" }}>
                      <PrixVenteMetreCarre
                        label="Prix € / m² (Haut)"
                        value={
                          surface > 0
                            ? arrondi(
                                (fraisAgenceHaut + prixVenteHaut) / surface,
                                10
                              )
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="loyer" className="vente-lot-tab">
                <Row>
                  <Col xs="12">
                    <span style={{ display: "inline-flex" }}>
                      <Montant
                        label="Loyer"
                        value={loyer}
                        onChange={(e) =>
                          handleModification("loyer", e.target.value)
                        }
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="tva" className="vente-lot-tab">
                <Row>
                  <Col xs="12">
                    <TVA
                      value={tva}
                      onChange={(e) =>
                        handleModification("tva", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                {summary}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default VenteLot;
