// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: none !important;
  background-color: #f3f3f3 !important;
}

.vente-lot-tab {
  background-color: #f3f3f3;
  padding: 20px;
}

.nav-tabs .nav-link {
  margin-bottom: -2px !important;
}

.is-modified {
  padding-left: 10px;
  color: red;
  font-size: 10px;
}

.vente-lot-nombre {
  background-color: #f3f3f3 !important;
  color: #4f4f4f;
  border: none;
  margin: 0px;
  margin-right: 10px;
  padding: 7px;
  padding-left: 12px;
  padding-right: 12px;
  font-size: 0.7em;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
}

.vente-lot-summary {
  margin-top: 10px !important;
  font-size: 14px;
}

.vente-lot-trash {
  color: red;
  top: 45px;
  right: -130px;
  position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/VenteLot.css"],"names":[],"mappings":"AAAA;;EAEE,uBAAuB;EACvB,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,kBAAkB;EAClB,UAAU;EACV,eAAe;AACjB;;AAEA;EACE,oCAAoC;EACpC,cAAc;EACd,YAAY;EACZ,WAAW;EACX,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;EACE,2BAA2B;EAC3B,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,SAAS;EACT,aAAa;EACb,kBAAkB;AACpB","sourcesContent":[".nav-tabs .nav-link.active,\r\n.nav-tabs .nav-item.show .nav-link {\r\n  border: none !important;\r\n  background-color: #f3f3f3 !important;\r\n}\r\n\r\n.vente-lot-tab {\r\n  background-color: #f3f3f3;\r\n  padding: 20px;\r\n}\r\n\r\n.nav-tabs .nav-link {\r\n  margin-bottom: -2px !important;\r\n}\r\n\r\n.is-modified {\r\n  padding-left: 10px;\r\n  color: red;\r\n  font-size: 10px;\r\n}\r\n\r\n.vente-lot-nombre {\r\n  background-color: #f3f3f3 !important;\r\n  color: #4f4f4f;\r\n  border: none;\r\n  margin: 0px;\r\n  margin-right: 10px;\r\n  padding: 7px;\r\n  padding-left: 12px;\r\n  padding-right: 12px;\r\n  font-size: 0.7em;\r\n  align-items: center;\r\n  border-radius: 50%;\r\n  justify-content: center;\r\n}\r\n\r\n.vente-lot-summary {\r\n  margin-top: 10px !important;\r\n  font-size: 14px;\r\n}\r\n\r\n.vente-lot-trash {\r\n  color: red;\r\n  top: 45px;\r\n  right: -130px;\r\n  position: relative;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
