// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.step-title {
  font-size: 24px;
  font-weight: bold;
  padding-left: 25px;
  padding-top: 25px;
  margin-bottom: 15px;
}

.step-title-inside {
  font-size: 24px;
  font-weight: bold;
  padding-left: 0px;
  padding-top: 25px;
  margin-bottom: 15px;
}

.step-subtitle-inside {
  font-size: 18px;
  font-weight: bold;
  color: #3cb599 !important;
}

.RFS-StepButton.active {
  background-color: #3cb599 !important;
}
.RFS-StepButton.completed {
  background-color: #2b806c !important;
}

.step-body {
  margin-left: 40px;
  margin-top: 10px;
}

.form-control {
  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;
  font-weight: bold !important;
  color: #3cb599 !important;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ProjectStep.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,qDAAqD;EACrD,4BAA4B;EAC5B,yBAAyB;AAC3B","sourcesContent":[".step-title {\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  padding-left: 25px;\r\n  padding-top: 25px;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.step-title-inside {\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  padding-left: 0px;\r\n  padding-top: 25px;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.step-subtitle-inside {\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n  color: #3cb599 !important;\r\n}\r\n\r\n.RFS-StepButton.active {\r\n  background-color: #3cb599 !important;\r\n}\r\n.RFS-StepButton.completed {\r\n  background-color: #2b806c !important;\r\n}\r\n\r\n.step-body {\r\n  margin-left: 40px;\r\n  margin-top: 10px;\r\n}\r\n\r\n.form-control {\r\n  padding: 0.375rem 2.25rem 0.375rem 0.75rem !important;\r\n  font-weight: bold !important;\r\n  color: #3cb599 !important;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
