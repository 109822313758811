import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import FilterableTable from "react-filterable-table";

import "react-filterable-table/dist/style.css";

import { Link, useNavigate } from "react-router-dom";
import Navbar from "./layout/Navbar";

import TypesProjet from "./Components/data/TypeProjet.json";
import { UserProfile } from "./Components/UserProfile";
import { FreeAccess } from "./Components/FreeAccess";

import { getData, prettyEuro, putData, toastConfig } from "../utils";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Card,
  CardBody,
  Badge,
} from "reactstrap";

import CleanDate from "./Components/CleanDate";
import { FaEye } from "react-icons/fa";

import "./AdministrationPage.css";

function AdministrationPage() {
  const navigate = useNavigate();
  const [isAdmin, setIsAdmin] = React.useState(false);
  const [projets, setProjets] = React.useState([]);
  const [utilisateurs, setUtilisateurs] = React.useState([]);
  const [subPage, setSubPage] = useState("projects");

  const toggleSubPage = (sub) => {
    setSubPage(sub);
  };

  const toggleProfile = async (uniqueId, newProfile) => {
    try {
      console.log(`toggleProfile`);
      await putData(`/v1/utilisateurs/${uniqueId}`, {
        isAdmin: newProfile,
      });
      await _refreshUsers();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const resetFreeUse = async (uniqueId) => {
    try {
      console.log(`resetFreeUse`);
      await putData(`/v1/utilisateurs/${uniqueId}`, {
        freeUse: null,
      });
      await _refreshUsers();
    } catch (error) {
      toast.error(error.message);
    }
  };

  const _refreshUsers = async () => {
    try {
      const us = await getData("/v1/utilisateurs");
      setUtilisateurs(
        us.sort(function (a, b) {
          if (`${a.nom} ${a.prenom}` < `${b.nom} ${b.prenom}`) {
            return -1;
          }
          if (`${a.nom} ${a.prenom}` > `${b.nom} ${b.prenom}`) {
            return 1;
          }
          return 0;
        })
      );
    } catch (error) {
      toast.error(error.message);
    }
  };

  const projectRows = projets.map((projet, index) => {
    return (
      <tr key={index}>
        <td>
          <Link to={`/utilisateurs/${projet.utilisateurUniqueId}`}>
            {projet.Utilisateur.prenom} {projet.Utilisateur.nom}
          </Link>
        </td>
        <td>{projet.libelle} </td>
        <td>{TypesProjet[projet.typeProjet]} </td>
        <td>{prettyEuro(projet.prixAchat)} </td>
        <td>
          {projet.codePostal} {projet.ville}{" "}
        </td>
        <td>
          <Link to={`/projets/${projet.uniqueId}`}>
            <FaEye />
          </Link>
        </td>
      </tr>
    );
  });

  const userRows = utilisateurs.map((utilisateur, index) => {
    return (
      <tr key={index}>
        <td>
          <Link to={`/utilisateurs/${utilisateur.uniqueId}`}>
            {utilisateur.nom} {utilisateur.prenom}
          </Link>
        </td>
        <td>{utilisateur.email}</td>
        <td onClick={() => resetFreeUse(utilisateur.uniqueId)}>
          <FreeAccess user={utilisateur} />
        </td>
        <td>
          {utilisateur.isPremium ? (
            <Badge color="success">Illimité</Badge>
          ) : (
            <Badge color="info">Abonnement</Badge>
          )}{" "}
        </td>
        <td
          onClick={() =>
            toggleProfile(utilisateur.uniqueId, !utilisateur.isAdmin)
          }
        >
          <UserProfile user={utilisateur} />
        </td>
        <td>
          <CleanDate date={utilisateur.createdAt} />
        </td>
      </tr>
    );
  });

  useEffect(() => {
    async function getUseEffectData() {
      try {
        const profiles = await getData("/v1/me/profiles");
        if (profiles.includes("ADMIN")) {
          setIsAdmin(true);
        }

        const ps = await getData("/v1/projets");
        setProjets(
          ps.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
        );

        await _refreshUsers();
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [navigate]);
  return (
    <div>
      <Navbar isLoggedIn={true} isAdmin={isAdmin} />
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <nav aria-label="breadcrumb">
            <h2 className="pagetitle">Administration</h2>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/dashboard">Tableau de bord</Link>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Administration
              </li>
            </ol>
          </nav>
        </div>
      </nav>
      <section className="vh-875">
        <Container fluid className="mt-20">
          <Row>
            <Col xs={12}>
              <Card className="step-body">
                <CardBody>
                  <Nav tabs>
                    <NavItem>
                      <NavLink active={subPage === "projects"} href="#">
                        <div
                          className="menu-item"
                          onClick={() => toggleSubPage("projects")}
                        >
                          <span className="pt-10">Tous les projets</span>
                        </div>
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink active={subPage === "statistiques"} href="#">
                        <div
                          className="menu-item"
                          onClick={() => toggleSubPage("statistiques")}
                        >
                          <span className="pt-10">Statistiques</span>
                        </div>
                      </NavLink>
                    </NavItem> */}
                    <NavItem>
                      <NavLink active={subPage === "utilisateurs"} href="#">
                        <div
                          className="menu-item"
                          onClick={() => toggleSubPage("utilisateurs")}
                        >
                          <span className="pt-10">Utilisateurs</span>
                        </div>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={subPage}>
                    <TabPane tabId="utilisateurs">
                      <Row>
                        <Col sm="12" className="mt-40 ml-10">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Nom</th>
                                <th>eMail</th>
                                <th>Type d'inscription</th>
                                <th>Profile</th>
                                <th>Inscription</th>
                              </tr>
                            </thead>
                            <tbody>{userRows}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="projects">
                      <Row>
                        <Col sm="12" className="mt-40 ml-10">
                          <Table responsive>
                            <thead>
                              <tr>
                                <th>Porteur</th>
                                <th>Libellé</th>
                                <th>Type de projet</th>
                                <th>Prix d'achat</th>
                                <th>Localisation</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>{projectRows}</tbody>
                          </Table>
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tabId="statistiques">
                      <Row>
                        <Col sm="12" className="mt-40 ml-10"></Col>
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <ToastContainer />
      </section>
    </div>
  );
}

export default AdministrationPage;
