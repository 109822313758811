import React, { useEffect, useState } from "react";
import { FormGroup, Label, Input } from "reactstrap";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { BsTrash } from "react-icons/bs";

import {
  getData,
  postData,
  deleteData,
  putData,
  toastConfig,
  arrondi,
} from "../../utils";
import { TauxTVA, refTauxTVAValue } from "./TauxTVA";
import MontantTVA from "./MontantTVA";
import PrixHorsTaxe from "./PrixHorsTaxe";
import PrixTTC from "./PrixTTC";

import "./Commission.css";

const Commission = (props) => {
  const [commissionUid, setCommissionUid] = useState("");
  const [tauxTVA, setTauxTVA] = useState("");
  const [prixHorsTaxe, setPrixHorsTaxe] = useState("");
  const [projetLots, setProjetLots] = useState([]);
  const [projetCommissionLots, setProjetCommissionLots] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    setCommissionUid(props.uniqueId);
    console.log(props.uniqueId);
    async function getUseEffectData() {
      try {
        const p = await getData(`/v1/projets/${props.projet}`);
        const projetCommission = await getData(
          `/v1/projets/${props.projet}/commissions/${props.uniqueId}`
        );

        setProjetLots(p.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder));

        setProjetCommissionLots(
          projetCommission.ProjetCommissionsLots.map((projetCommissionLot) => {
            return projetCommissionLot.projetLotUniqueId;
          })
        );

        setPrixHorsTaxe(projetCommission.prixHorsTaxe);
        setTauxTVA(projetCommission.tauxTVA);
      } catch (error) {
        console.log(error);
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [commissionUid, navigate, props.uniqueId, props.projet]);

  const updateCommission = async (body) => {
    try {
      await putData(
        `/v1/projets/${props.projet}/commissions/${props.uniqueId}`,
        body
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleModification = async (type, value) => {
    try {
      switch (type) {
        case "tauxTVA":
          await updateCommission({
            tauxTVA: value !== "" ? arrondi(value, 10) : null,
          });
          setTauxTVA(value);
          break;
        case "prixHorsTaxe":
          await updateCommission({
            prixHorsTaxe: value !== "" ? arrondi(value, 10) : null,
          });
          setPrixHorsTaxe(value !== "" ? arrondi(value, 10) : "");
          break;
        default:
          toast.error(`Unmanaged type ${type}`, toastConfig);
      }
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleSelectLot = async (id, value) => {
    try {
      const p = await getData(`/v1/projets/${props.projet}`);

      if (value === true) {
        await postData(
          `/v1/projets/${props.projet}/commissions/${props.uniqueId}/lots`,
          {
            projetLotUniqueId: id,
          }
        );
      } else {
        await deleteData(
          `/v1/projets/${props.projet}/commissions/${props.uniqueId}/lots/${id}`
        );
      }
      const projetCommission = await getData(
        `/v1/projets/${props.projet}/commissions/${props.uniqueId}`
      );
      setProjetCommissionLots(
        projetCommission.ProjetCommissionsLots.map((projetCommissionLot) => {
          return projetCommissionLot.projetLotUniqueId;
        })
      );
      setProjetLots([]);
      setProjetLots(p.ProjetLots.sort((a, b) => a.itemOrder - b.itemOrder));
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const lots = projetLots.map((projetLot, index) => {
    return (
      <FormGroup key={index} check inline className="mt-6">
        <Label check>Lot n°{projetLot.itemOrder}</Label>
        <Input
          type="checkbox"
          checked={projetCommissionLots.includes(projetLot.uniqueId)}
          onChange={(e) =>
            handleSelectLot(projetLot.uniqueId, e.target.checked)
          }
        />
      </FormGroup>
    );
  });

  return (
    <div>
      <span style={{ display: "inline-flex" }}>
        <PrixHorsTaxe
          prixhorstaxeclassname="ml-10"
          value={prixHorsTaxe ? prixHorsTaxe : ""}
          onChange={(e) => handleModification("prixHorsTaxe", e.target.value)}
        />
        <TauxTVA
          tauxtvaclassname="ml-10"
          value={
            tauxTVA !== null && tauxTVA >= 0 && tauxTVA !== null ? tauxTVA : ""
          }
          onChange={(e) => handleModification("tauxTVA", e.target.value)}
        />
        <MontantTVA
          montanttvaclassname="ml-10"
          value={
            prixHorsTaxe && tauxTVA !== null && prixHorsTaxe > 0 && tauxTVA >= 0
              ? arrondi(prixHorsTaxe * refTauxTVAValue[tauxTVA], 10)
              : ""
          }
          disabled
        />
        <PrixTTC
          montanttvaclassname="ml-10"
          value={
            prixHorsTaxe && tauxTVA !== null && prixHorsTaxe > 0 && tauxTVA >= 0
              ? arrondi(
                  prixHorsTaxe * refTauxTVAValue[tauxTVA] + prixHorsTaxe,
                  10
                )
              : ""
          }
          disabled
        />

        <FormGroup>
          <BsTrash
            color="red"
            size={20}
            className="pointer taxe-fonciere-trash"
            onClick={props.removeCommission}
          />
        </FormGroup>
      </span>
      <FormGroup inline className="ml-10">
        <Label>Lot(s)</Label>
        <br />
        {lots}
      </FormGroup>
      <hr />
    </div>
  );
};

export default Commission;
