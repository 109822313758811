import { arrondi } from "../../../utils";

export const typesBien = ["Ancien", "Neuf", "Terrain"];
export const tvaEmoluments = 0.2;

export const tauxTaxeCommunale = 0.012;
export const tauxFraisAssiette = 0.00107;
export const tauxContributionSecuriteImmobiliere = 0.001;
export const tauxDroitDepartementEnregistrement = 0.045;
export const tauxReduitDroitDepartementEnregistrement = 0.038;
export const departementsTauxReduitDroitDepartementEnregistrement = ["56"];
export const montantEmolumentsDeFormaliteEtDebours = 1360;

export const calculDepartement = (codePostal) => {
  //console.log(codePostal);
  if (codePostal) {
    const departement = codePostal.substring(0, 2);
    return departement;
  }

  return "75";
};

export const calculTauxDroitDepartementEnregistrment = (codePostal) => {
  const departement = calculDepartement(codePostal);

  if (
    departementsTauxReduitDroitDepartementEnregistrement.includes(departement)
  ) {
    return tauxReduitDroitDepartementEnregistrement;
  }

  return tauxDroitDepartementEnregistrement;
};
export const calculMontantFraisNotaire = (montant, type, codePostal) => {
  const departement = calculDepartement(codePostal);

  //console.log(`montant : ${montant}`);
  //console.log(`departement : ${departement}`);
  const _emoluments = calculEmolumentsNotaire(montant);
  //console.log(`emoluments : ${_emoluments}`);
  const _droitsEtTaxes = calculDroitsEtTaxes(montant, type, codePostal);
  //console.log(`droitsEtTaxes : ${_droitsEtTaxes}`);
  let montantFraisNotaire = 0;
  montantFraisNotaire =
    montantEmolumentsDeFormaliteEtDebours +
    _droitsEtTaxes[0] +
    _droitsEtTaxes[1] +
    _droitsEtTaxes[2] +
    _droitsEtTaxes[3] +
    _emoluments[0] +
    _emoluments[1] +
    _emoluments[2] +
    _emoluments[3] +
    (_emoluments[0] + _emoluments[1] + _emoluments[2] + _emoluments[3]) *
      tvaEmoluments;
  return montantFraisNotaire;
};

export const calculDroitsEtTaxes = (montant, type, codePostal) => {
  let droitDepartementEnregistrement = 0;
  let taxeCommunale = 0;
  let fraisAssiette = 0;
  let contributionSecuriteImmobiliere = 0;
  //const departement = calculDepartement(codePostal);
  let taux = calculTauxDroitDepartementEnregistrment(codePostal);

  if (type !== 1) {
    droitDepartementEnregistrement = montant * taux;
  }
  taxeCommunale = montant * tauxTaxeCommunale;
  fraisAssiette = montant * tauxFraisAssiette;
  contributionSecuriteImmobiliere =
    montant * tauxContributionSecuriteImmobiliere;

  return [
    Math.round(droitDepartementEnregistrement),
    Math.round(taxeCommunale),
    Math.round(fraisAssiette),
    Math.round(contributionSecuriteImmobiliere),
  ];
};

export const tauxEmolument = [0.03945, 0.01627, 0.01085, 0.00814];
export const tauxTVA = 0.2;
export const borneTrancheEmolument = [6500, 17000, 60000];

export const calculEmolumentsNotaire = (montant) => {
  //console.log(`montant : ${montant}`);
  let m = arrondi(montant, 10);
  let montant1 = 0;
  if (m >= borneTrancheEmolument[0]) {
    montant1 = borneTrancheEmolument[0] * tauxEmolument[0];
  } else {
    montant1 = m * tauxEmolument[0];
  }
  let montant2 = 0;
  if (m >= borneTrancheEmolument[1]) {
    montant2 =
      (borneTrancheEmolument[1] - borneTrancheEmolument[0]) * tauxEmolument[1];
  } else {
    montant2 = (m - borneTrancheEmolument[0]) * tauxEmolument[1];
  }
  let montant3 = 0;
  let montant4 = 0;
  if (m >= borneTrancheEmolument[2]) {
    montant3 =
      (borneTrancheEmolument[2] - borneTrancheEmolument[1]) * tauxEmolument[2];
    montant4 = (m - borneTrancheEmolument[2]) * tauxEmolument[3];
  } else {
    montant3 = (m - borneTrancheEmolument[1]) * tauxEmolument[2];
  }

  return [
    Math.round(montant1),
    Math.round(montant2),
    Math.round(montant3),
    Math.round(montant4),
  ];
};
