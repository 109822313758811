import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

import { Label, Container, Row, Col, Input, FormGroup } from "reactstrap";
import { putData, getData, toastConfig, arrondi } from "../../utils";
import CurrencyInput from "./CurrencyInput";
import "./Financement.css";
import SmallPercentInput from "./SmallPercentInput";

import { FaQuestionCircle } from "react-icons/fa";

import {
  calculApport,
  calculCommissionEngagement,
  calculCoutProjet,
  calculHypotheque,
  calculFraisNotaireAchat,
  calculInteretsEmprunt,
  calculMontantAFinancer,
  calculMontantTravaux,
} from "./helpers/calculsProjet";

const Financement = (props) => {
  const [uid, setUid] = useState("");
  const [typeFinancement, setTypeFinancement] = useState(true);
  const [coutProjet, setCoutProjet] = useState(0);
  const [montantExpertise, setMontantExpertise] = useState(0);
  const [montantAFinancer, setMontantAFinancer] = useState(0);
  const [montantTravaux, setMontantTravaux] = useState(0);
  const [pourcentageApport, setPourcentageApport] = useState(20);
  //const [prixAchat, setPrixAchat] = useState(0);
  const [interetsEmprunt, setInteretsEmprunt] = useState(0);
  const [pourcentageInteretsEmprunt, setPourcentageInteretsEmprunt] =
    useState(4);
  const [dureeInteretsEmprunt, setDureeInteretsEmprunt] = useState(12);
  const [commissionEngagement, setCommissionEngagement] = useState(0);
  const [pourcentageCommissionEngagement, setPourcentageCommissionEngagement] =
    useState(0);
  const [dureeCommissionEngagement, setDureeCommissionEngagement] = useState(0);
  const [apport, setApport] = useState(0);
  //const [fraisAgence, setFraisAgence] = useState(0);
  //const [fraisNotaire, setFraisNotaire] = useState(0);
  const [fraisDossier, setFraisDossier] = useState(3000);
  const [hypotheque, setHypotheque] = useState(0);
  const [pourcentageHypotheque, setPourcentageHypotheque] = useState(0);
  const [financementParticipatif, setFinancementParticipatif] = useState(0);
  const [modeFinancementProjet, setModeFinancementProjet] = useState(0);

  useEffect(() => {
    setUid(props.projet);
    async function getUseEffectData() {
      try {
        console.log(`useEffect`);
        const projet = await getData(`/v1/projets/${props.projet}`);
        let _travaux = calculMontantTravaux(projet.ProjetTravauxes);
        setMontantTravaux(_travaux);
        setPourcentageApport(projet.pourcentageApport);
        setModeFinancementProjet(projet.modeFinancementProjet);
        setPourcentageInteretsEmprunt(projet.pourcentageInteretsEmprunt);
        setPourcentageCommissionEngagement(
          projet.pourcentageCommissionEngagement
        );
        setDureeCommissionEngagement(projet.dureeCommissionEngagement);
        setDureeInteretsEmprunt(projet.dureeInteretsEmprunt);

        setPourcentageHypotheque(projet.pourcentageHypotheque.toFixed(2));
        setFraisDossier(projet.fraisDossier ? projet.fraisDossier : 3000);
        props.handleFraisDossier(
          projet.fraisDossier ? projet.fraisDossier : 3000
        );
        setFinancementParticipatif(projet.financementParticipatif);

        let _coutProjet = calculCoutProjet(projet);
        //console.log(`getUseEffectData`);
        setCoutProjet(_coutProjet.toFixed(2));

        let _apport = calculApport(projet);
        //console.log(`setApport : ${_apport}`);
        setApport(_apport.toFixed(2));

        let _montantAFinancer = calculMontantAFinancer(projet);
        //console.log(`setMontantAFinancer : ${_montantAFinancer}`);
        setMontantAFinancer(_montantAFinancer.toFixed(2));
        props.handleMontantAFinancer(_montantAFinancer);

        let _interetsEmprunt = calculInteretsEmprunt(projet);
        setInteretsEmprunt(_interetsEmprunt ? _interetsEmprunt.toFixed(2) : 0);

        let _hypotheque = calculHypotheque(projet);
        setHypotheque(_hypotheque ? _hypotheque.toFixed(2) : 0);

        let _commissionEngagement = calculCommissionEngagement(projet);
        setCommissionEngagement(
          _commissionEngagement ? _commissionEngagement.toFixed(2) : 0
        );

        setMontantExpertise(projet.montantExpertise);
        props.handleMontantExpertise(projet.montantExpertise);

        setTypeFinancement(projet.typeFinancement);
        //toggleMode(projet.typeFinancement);
      } catch (error) {
        console.log(error);
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }

    getUseEffectData();
  }, [uid, coutProjet, pourcentageApport, typeFinancement]);

  const toggleModeFinancementProjet = async (mode) => {
    console.log(`toggleModeFinancementProjet`);
    setModeFinancementProjet(mode);
    await updateModeFinancementProjet(mode);
    console.log(mode);
    const projet = await getData(`/v1/projets/${props.projet}`);
    let _coutProjet = calculCoutProjet(projet);
    console.log(`toggleModeFinancementProjet`);
    setCoutProjet(_coutProjet.toFixed(2));
    let _apport = calculApport(projet);
    console.log(`setApport : ${_apport}`);
    setApport(_apport.toFixed(2));
    let _montantAFinancer = calculMontantAFinancer(projet);
    setMontantAFinancer(_montantAFinancer.toFixed(2));
    props.handleModeFinancementProjet(mode);
  };

  const toggleMode = async (mode) => {
    console.log(`toggleMode`);
    setTypeFinancement(mode);
    await updateTypeFinancement(mode);
    const projet = await getData(`/v1/projets/${props.projet}`);
    //console.log(mode);
    let _coutProjet = calculCoutProjet(projet);
    //console.log(`montantTravaux : ${montantTravaux}`);
    let _interetsEmprunt = calculInteretsEmprunt(projet);
    let _commissionEngagement = calculCommissionEngagement(projet);
    let _hypotheque = calculHypotheque(projet);
    //console.log(`projet.chargeVendeur : ${projet.chargeVendeur}`);
    let _montantAchat =
      projet.prixAchat +
      projet.fraisAgence +
      calculFraisNotaireAchat(
        projet.chargeVendeur === 0
          ? projet.prixAchat + projet.fraisAgence
          : projet.prixAchat,
        1,
        projet.codePostal
      );
    if (mode === true) {
      /* console.log(`${_montantAchat} +
      ${fraisDossier} +
      ${montantTravaux} +
      ${_interetsEmprunt} +
      ${_commissionEngagement} +
      ${_hypotheque};`); */
      _coutProjet =
        _montantAchat +
        fraisDossier +
        montantTravaux +
        _interetsEmprunt +
        _commissionEngagement +
        _hypotheque;
    } else {
      _coutProjet = _montantAchat;
    }
    //console.log(`toggleMode`);
    setCoutProjet(_coutProjet.toFixed(2));
    //let _apport = calculApport(projet);
    let _apport = calculApport(projet);
    //console.log(`setApport : ${_apport}`);
    setApport(_apport.toFixed(2));

    let _montantAFinancer = calculMontantAFinancer(projet);

    setMontantAFinancer(_montantAFinancer.toFixed(2));
    props.handleMontantAFinancer(_montantAFinancer);
    props.handleApport(_apport);
    props.handleInteretsEmprunt(_interetsEmprunt);
    props.handleCommissionEngagement(_commissionEngagement);
    props.handleHypotheque(_hypotheque);
  };

  const handlePourcentageApport = async (value) => {
    try {
      console.log(value);
      console.log(montantAFinancer);
      setPourcentageApport(value);
      await putData(`/v1/projets/${uid}`, {
        pourcentageApport: value,
      });
      const projet = await getData(`/v1/projets/${props.projet}`);
      let _apport = calculApport(projet);
      console.log(`setApport : ${_apport}`);

      setApport(_apport.toFixed(2));
      props.handleApport(_apport);
      let _montantAFinancer = calculMontantAFinancer(projet);
      setMontantAFinancer(_montantAFinancer.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handlePourcentageInteretsEmprunt = async (value) => {
    try {
      console.log(value);
      setPourcentageInteretsEmprunt(value);
      await putData(`/v1/projets/${uid}`, {
        pourcentageInteretsEmprunt: parseFloat(value),
      });
      const projet = await getData(`/v1/projets/${props.projet}`);
      let _interetsEmprunt = calculInteretsEmprunt(projet);
      setInteretsEmprunt(_interetsEmprunt.toFixed(2));
      props.handleInteretsEmprunt(_interetsEmprunt.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handlePourcentageCommissionEngagement = async (value) => {
    try {
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        pourcentageCommissionEngagement: value,
      });
      setPourcentageCommissionEngagement(value);
      const projet = await getData(`/v1/projets/${props.projet}`);
      let _commissionEngagement = calculCommissionEngagement(projet);
      setCommissionEngagement(_commissionEngagement.toFixed(2));
      props.handleCommissionEngagement(_commissionEngagement.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handlePourcentageHypotheque = async (value) => {
    try {
      console.log(value);
      console.log(montantAFinancer);

      setPourcentageHypotheque(value);
      let h = ((montantAFinancer / 100) * value).toFixed(2);
      setHypotheque(h);
      props.handleHypotheque((montantAFinancer / 100) * value);

      await putData(`/v1/projets/${uid}`, {
        pourcentageHypotheque: value,
      });
    } catch (error) {
      console.log(error);
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleHypotheque = async (value) => {
    try {
      console.log(value);

      setHypotheque(value);
      let p = ((value * 100) / montantAFinancer).toFixed(2);
      setPourcentageHypotheque(p);
      props.handleHypotheque(value);

      await putData(`/v1/projets/${uid}`, {
        hypotheque: parseFloat(value),
      });
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleFraisDossier = async (value) => {
    try {
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        fraisDossier: value,
      });
      setFraisDossier(value);
      props.handleFraisDossier(value);
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleFinancementParticipatif = async (value) => {
    try {
      if (isNaN(value)) {
        value = 0;
      }
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        financementParticipatif: value,
      });
      const p = await getData(`/v1/projets/${uid}`);
      let _montantAFinancer = calculMontantAFinancer(p);
      setMontantAFinancer(_montantAFinancer.toFixed(2));
      props.handleMontantAFinancer(_montantAFinancer);
      setFinancementParticipatif(value);
      props.handleFinancementParticipatif(value);
      let _coutProjet = calculCoutProjet(p);
      setCoutProjet(_coutProjet.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleMontantExpertise = async (value) => {
    try {
      if (isNaN(value)) {
        value = 0;
      }
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        montantExpertise: value,
      });
      const p = await getData(`/v1/projets/${uid}`);
      setMontantExpertise(value);
      let _montantAFinancer = calculMontantAFinancer(p);
      setMontantAFinancer(_montantAFinancer.toFixed(2));
      props.handleMontantExpertise(value);
      let _coutProjet = calculCoutProjet(p);
      setCoutProjet(_coutProjet.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleDureeInteretsEmprunt = async (value) => {
    try {
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        dureeInteretsEmprunt: value,
      });
      setDureeInteretsEmprunt(value);
      const projet = await getData(`/v1/projets/${props.projet}`);
      let _interetsEmprunt = calculInteretsEmprunt(projet);
      setInteretsEmprunt(_interetsEmprunt.toFixed(2));
      props.handleInteretsEmprunt(_interetsEmprunt.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleDureeCommissionEngagement = async (value) => {
    try {
      console.log(value);
      await putData(`/v1/projets/${uid}`, {
        dureeCommissionEngagement: value,
      });
      setDureeCommissionEngagement(value);
      const projet = await getData(`/v1/projets/${props.projet}`);
      let _commissionEngagement = calculCommissionEngagement(projet);
      setCommissionEngagement(_commissionEngagement.toFixed(2));
      //console.log(_commissionEngagement.toFixed(2));
      props.handleCommissionEngagement(_commissionEngagement.toFixed(2));
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const updateTypeFinancement = async (value) => {
    try {
      /* console.log(value);
      console.log(typeFinancement);
      console.log(props.projet); */
      if (typeFinancement !== value) {
        await putData(`/v1/projets/${uid ? uid : props.projet}`, {
          typeFinancement: value,
        });
        setTypeFinancement(value);
      }
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const updateModeFinancementProjet = async (value) => {
    try {
      await putData(`/v1/projets/${uid}`, {
        modeFinancementProjet: value,
      });
      setModeFinancementProjet(value);
    } catch (error) {
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };
  return (
    <Container fluid>
      <Tooltip id="my-tooltip" />
      <Row>
        <Col xs={12}>
          <div className="financement center">
            <div>
              <Label>Comment financez-vous votre projet ?</Label>
            </div>
            <span
              onClick={() => toggleModeFinancementProjet(true)}
              className={`toggle ${modeFinancementProjet ? "active" : ""}`}
            >
              Fonds propres
            </span>
            <span
              onClick={() => toggleModeFinancementProjet(false)}
              className={`toggle ${!modeFinancementProjet ? "active" : ""}`}
            >
              Financement
            </span>
          </div>
        </Col>
      </Row>
      {!modeFinancementProjet && (
        <Row className="mt-10">
          <Col xs={12}>
            <div className="financement center">
              <div>
                <Label>Que souhaitez-vous financer ?</Label>
              </div>
              <span
                onClick={() => toggleMode(true)}
                className={`toggle ${typeFinancement ? "active" : ""}`}
              >
                Le projet
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="calcul pour : Prix du bien FAI+frais notaire+travaux"
                >
                  <FaQuestionCircle
                    color={typeFinancement ? "white" : ""}
                    className="ml-10"
                  />
                </a>
              </span>
              <span
                onClick={() => toggleMode(false)}
                className={`toggle ${!typeFinancement ? "active" : ""}`}
              >
                L'achat du bien
                <a
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Prix du bien FAI"
                >
                  <FaQuestionCircle
                    color={!typeFinancement ? "white" : ""}
                    className="ml-10"
                  />
                </a>
              </span>
            </div>
          </Col>
        </Row>
      )}
      {!modeFinancementProjet && (
        <Row className="mt-10">
          <Col xs={12}>
            <h4>Coût projet / Montant à financer / Apport</h4>
            <span style={{ display: "inline-flex" }}>
              <FormGroup>
                <Label>
                  Cout du projet {!typeFinancement ? ` (hors F.F/TRX/NOT)` : ``}
                </Label>
                <CurrencyInput value={coutProjet} disabled />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Montant à financer</Label>
                <CurrencyInput disabled value={montantAFinancer} />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Apport</Label>
                <CurrencyInput disabled value={apport} />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>%</Label>
                <SmallPercentInput
                  onChange={(e) => handlePourcentageApport(e.target.value)}
                  value={pourcentageApport}
                />
              </FormGroup>
            </span>
          </Col>
        </Row>
      )}

      {!modeFinancementProjet && (
        <Row>
          <Col xs="12">
            <h4>
              Intérêts / Commission
              <a
                data-tooltip-id="my-tooltip"
                data-tooltip-content="Pour un calcul précis, inclure le taux du prêt + l'assurance + la marge de la banque dans le %"
              >
                <FaQuestionCircle className="ml-10" />
              </a>
            </h4>
            <span style={{ display: "inline-flex" }}>
              <FormGroup>
                <Label>Intérêts d'emprunt</Label>
                <CurrencyInput value={interetsEmprunt} disabled />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>%</Label>
                <SmallPercentInput
                  onChange={(e) =>
                    handlePourcentageInteretsEmprunt(e.target.value)
                  }
                  value={pourcentageInteretsEmprunt}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Mois</Label>
                <Input
                  className="small-input"
                  onChange={(e) =>
                    handleDureeInteretsEmprunt(arrondi(e.target.value, 10))
                  }
                  value={dureeInteretsEmprunt}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Com. eng.</Label>
                <CurrencyInput value={commissionEngagement} disabled />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>%</Label>
                <SmallPercentInput
                  onChange={(e) =>
                    handlePourcentageCommissionEngagement(e.target.value)
                  }
                  value={pourcentageCommissionEngagement}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Mois</Label>
                <Input
                  className="small-input"
                  onChange={(e) =>
                    handleDureeCommissionEngagement(arrondi(e.target.value, 10))
                  }
                  value={dureeCommissionEngagement}
                />
              </FormGroup>
            </span>
          </Col>
        </Row>
      )}

      {!modeFinancementProjet && (
        <Row>
          <Col xs="12">
            <h4>Frais de dossier / Hypotheque / Financement participatif</h4>
            <span style={{ display: "inline-flex" }}>
              <FormGroup>
                <Label>Frais de dossier</Label>
                <CurrencyInput
                  onChange={(e) => setFraisDossier(e.target.value, 10)}
                  onBlur={(e) =>
                    handleFraisDossier(arrondi(e.target.value, 10))
                  }
                  value={fraisDossier}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Hypotheque</Label>
                <CurrencyInput
                  value={hypotheque}
                  onChange={(e) => handleHypotheque(e.target.value)}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>%</Label>
                <SmallPercentInput
                  onChange={(e) => handlePourcentageHypotheque(e.target.value)}
                  value={pourcentageHypotheque}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Financement participatif</Label>
                <CurrencyInput
                  onChange={(e) =>
                    setFinancementParticipatif(e.target.value, 10)
                  }
                  onBlur={(e) =>
                    handleFinancementParticipatif(arrondi(e.target.value, 10))
                  }
                  value={financementParticipatif}
                />
              </FormGroup>
              <FormGroup className="ml-10">
                <Label>Divers</Label>
                <CurrencyInput
                  onChange={(e) => setMontantExpertise(e.target.value, 10)}
                  onBlur={(e) =>
                    handleMontantExpertise(arrondi(e.target.value, 10))
                  }
                  value={montantExpertise}
                />
              </FormGroup>
            </span>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default Financement;
