// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-dashboard-graph {
  width: 100%;
  margin-bottom: 15px;
}

.card-dashboard-kpi {
  width: 100%;
  margin-bottom: 25px;
}

.card-dashboard-header {
  border-bottom: none !important;
  font-size: 18px;
}

.counter {
  text-align: center;
  font-weight: bold;
  color: #3cb599;
  font-size: 24px;
}

.lots-vendus {
  text-align: center;
  margin: 30px;
  margin-bottom: 0;
  color: #3cb599;
  font-size: 48px;
}

.lots-encours {
  text-align: center;
  margin: 30px;
  margin-bottom: 0;
  color: #3cb599;
  font-size: 24px;
}

.lots-vendus-legende {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/pages/DashboardPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;EAChB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,iBAAiB;AACnB","sourcesContent":[".welcome {\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  margin-top: 15px;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.card-dashboard-graph {\r\n  width: 100%;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.card-dashboard-kpi {\r\n  width: 100%;\r\n  margin-bottom: 25px;\r\n}\r\n\r\n.card-dashboard-header {\r\n  border-bottom: none !important;\r\n  font-size: 18px;\r\n}\r\n\r\n.counter {\r\n  text-align: center;\r\n  font-weight: bold;\r\n  color: #3cb599;\r\n  font-size: 24px;\r\n}\r\n\r\n.lots-vendus {\r\n  text-align: center;\r\n  margin: 30px;\r\n  margin-bottom: 0;\r\n  color: #3cb599;\r\n  font-size: 48px;\r\n}\r\n\r\n.lots-encours {\r\n  text-align: center;\r\n  margin: 30px;\r\n  margin-bottom: 0;\r\n  color: #3cb599;\r\n  font-size: 24px;\r\n}\r\n\r\n.lots-vendus-legende {\r\n  text-align: center;\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
