import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export default function SommePDFTable({ label, amount }) {
  const styles = StyleSheet.create({
    sommeView: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #000",
      backgroundColor: "#EEE",
      padding: 8,
      textAlign: "center",
    },
  });

  return (
    <>
      <Fragment>
        <View style={styles.sommeView}>
          <Text
            style={{
              width: `50%`,
              textAlign: `left`,
              fontSize: 12,
            }}
          >
            {label}
          </Text>
          <Text
            style={{
              width: `50%`,
              textAlign: `right`,
              fontSize: 12,
            }}
          >
            {amount}
          </Text>
        </View>
      </Fragment>
    </>
  );
}
