import React from "react";
import { Badge } from "reactstrap";

export const UserProfile = ({ user }) => {
  if (user) {
    return user.isAdmin ? (
      <Badge color="warning">Administrateur</Badge>
    ) : (
      <Badge color="success">Utilisateur</Badge>
    );
  } else {
    return <Badge colo="danger">TBD</Badge>;
  }
};
