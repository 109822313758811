import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export default function MargeNettePDFTable({ label, amount }) {
  const styles = StyleSheet.create({
    grandTotalView: {
      display: "flex",
      flexDirection: "row",
      backgroundColor: "#3BB599",
      color: "white",
      fontWeight: "bold",
      padding: 8,
      textAlign: "center",
    },
  });

  return (
    <>
      <Fragment>
        <View style={styles.grandTotalView}>
          <Text
            style={{
              width: `50%`,
              textAlign: `left`,
              fontSize: 12,
            }}
          >
            {label}
          </Text>
          <Text
            style={{
              width: `50%`,
              textAlign: `right`,
              fontSize: 12,
            }}
          >
            {amount}
          </Text>
        </View>
      </Fragment>
    </>
  );
}
