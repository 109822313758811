// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.welcome {
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
  margin-bottom: 15px;
}

.card-frais {
  width: 100%;
  margin-bottom: 15px;
}

.frais-notaire-title {
  padding-left: 20px;
}

.card-frais-header {
  border-bottom: none !important;
  font-size: 18px;
  font-weight: bold;
}

.input-header {
  font-size: 24px;
  color: #3cb599;
  font-weight: bold;
}

.card.inside-body {
  box-shadow: none !important;
  background-color: #f1f4f8;
  margin-top: 20px;
  margin-right: 20px;
  padding: 20px;
}

.titre-montant-frais-notaire {
  margin-top: 100px;
  font-size: 25px;
}

.montant-frais-notaire {
  margin-top: 50px;
  font-weight: bold;
  font-size: 50px;
}

.subheader-accordion {
  font-size: 12px;
}

.frais-notaire-summary {
  padding: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/FraisNotaire.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,2BAA2B;EAC3B,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".welcome {\r\n  font-size: 24px;\r\n  font-weight: bold;\r\n  margin-top: 15px;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.card-frais {\r\n  width: 100%;\r\n  margin-bottom: 15px;\r\n}\r\n\r\n.frais-notaire-title {\r\n  padding-left: 20px;\r\n}\r\n\r\n.card-frais-header {\r\n  border-bottom: none !important;\r\n  font-size: 18px;\r\n  font-weight: bold;\r\n}\r\n\r\n.input-header {\r\n  font-size: 24px;\r\n  color: #3cb599;\r\n  font-weight: bold;\r\n}\r\n\r\n.card.inside-body {\r\n  box-shadow: none !important;\r\n  background-color: #f1f4f8;\r\n  margin-top: 20px;\r\n  margin-right: 20px;\r\n  padding: 20px;\r\n}\r\n\r\n.titre-montant-frais-notaire {\r\n  margin-top: 100px;\r\n  font-size: 25px;\r\n}\r\n\r\n.montant-frais-notaire {\r\n  margin-top: 50px;\r\n  font-weight: bold;\r\n  font-size: 50px;\r\n}\r\n\r\n.subheader-accordion {\r\n  font-size: 12px;\r\n}\r\n\r\n.frais-notaire-summary {\r\n  padding: 30px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
