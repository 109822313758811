import React, { Fragment } from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

export default function ExportPDFTable({ data }) {
  const styles = StyleSheet.create({
    rowView: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      padding: 8,
      textAlign: "center",
    },
    headerView: {
      display: "flex",
      flexDirection: "row",
      borderTop: "1px solid #EEE",
      padding: 8,
      backgroundColor: "#EEE",
      textAlign: "center",
    },
  });

  return (
    <>
      {data && (
        <Fragment>
          {data["showHeader"] === true && (
            <View style={styles.headerView}>
              {data["column"].map((c, index) => (
                <Text
                  key={index}
                  style={{
                    width: `${
                      c.width ? `${c.width}%` : 100 / data["column"].length
                    }%`,
                    fontSize: 12,
                    textAlign: c.align ? c.align : `left`,
                  }}
                >
                  {c.header}
                </Text>
              ))}
            </View>
          )}
          {data["data"].map((rowData, dataIndex) => (
            <View key={dataIndex} style={styles.rowView}>
              {data["column"].map((c, index) => (
                <Text
                  key={index}
                  style={{
                    width: `${
                      c.width ? `${c.width}%` : 100 / data["column"].length
                    }%`,
                    textAlign: c.align ? c.align : `left`,
                    fontSize: 10,
                    color:
                      rowData[c.label].constructor === Object
                        ? rowData[c.label].color
                        : `black`,
                  }}
                >
                  {rowData[c.label].constructor === Object
                    ? rowData[c.label].value
                    : rowData[c.label]}
                </Text>
              ))}
            </View>
          ))}
        </Fragment>
      )}
    </>
  );
}
