// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.financement {
  background-color: #f3f3f3;
  padding-top: 10px;
  padding-bottom: 20px;
}

.financement-tab {
  background-color: #f3f3f3;
  padding: 20px;
}

.toggle {
  width: 200px;
  display: -moz-inline-block;
  display: -moz-inline-box;
  display: inline-block;
  background-color: white;
  border: 2px solid #3cb599;
  padding: 10px 30px 10px 30px;
  cursor: pointer;
}

.toggle.active {
  color: white;
  background-color: #3cb599;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/Financement.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,0BAA0B;EAC1B,wBAAwB;EACxB,qBAAqB;EACrB,uBAAuB;EACvB,yBAAyB;EACzB,4BAA4B;EAC5B,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,yBAAyB;AAC3B","sourcesContent":[".financement {\r\n  background-color: #f3f3f3;\r\n  padding-top: 10px;\r\n  padding-bottom: 20px;\r\n}\r\n\r\n.financement-tab {\r\n  background-color: #f3f3f3;\r\n  padding: 20px;\r\n}\r\n\r\n.toggle {\r\n  width: 200px;\r\n  display: -moz-inline-block;\r\n  display: -moz-inline-box;\r\n  display: inline-block;\r\n  background-color: white;\r\n  border: 2px solid #3cb599;\r\n  padding: 10px 30px 10px 30px;\r\n  cursor: pointer;\r\n}\r\n\r\n.toggle.active {\r\n  color: white;\r\n  background-color: #3cb599;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
