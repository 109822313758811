import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Row,
  FormGroup,
  Label,
  Input,
  Col,
  Container,
} from "reactstrap";

import { useNavigate } from "react-router-dom";

import { BsTrash } from "react-icons/bs";

import {
  prettyEuro,
  getData,
  deleteData,
  putData,
  postData,
  toastConfig,
  arrondi,
} from "../../utils";

import "./Travaux.css";
import { TypeTravaux, refTypeTravaux } from "./TypeTravaux";
import { TauxTVA, refTauxTVAValue, refTauxTVA } from "./TauxTVA";
import MontantTVA from "./MontantTVA";
import PrixHorsTaxe from "./PrixHorsTaxe";
import PrixTTC from "./PrixTTC";

function Travaux(props) {
  const [activeTab, setActiveTab] = useState("type");
  const [itemOrder, setItemOrder] = useState(0);
  const [typeTravaux, setTypeTravaux] = useState("");
  const [tauxTVA, setTauxTVA] = useState("");
  const [prixHorsTaxe, setPrixHorsTaxe] = useState("");
  const [projetLots, setProjetLots] = useState([]);
  const [projetTravauxLots, setProjetTravauxLots] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    async function getUseEffectData() {
      try {
        const travail = await getData(
          `/v1/projets/${props.projet}/travaux/${props.uniqueId}`
        );

        setProjetLots(props.lots);
        setProjetTravauxLots(
          travail.ProjetTravauxLots.map((projetTravauxLot) => {
            return projetTravauxLot.projetLotUniqueId;
          })
        );
        setItemOrder(travail.itemOrder);
        setTypeTravaux(travail.typeTravaux);
        setPrixHorsTaxe(travail.prixHorsTaxe);
        setTauxTVA(travail.tauxTVA);
      } catch (error) {
        console.log(JSON.parse(error).error.status === 403);

        if (JSON.parse(error).error.status === 403) {
          navigate(`/`);
        }
        toast.error(
          JSON.parse(error).error.data.message
            ? JSON.parse(error).error.data.message
            : "unknown error",
          toastConfig
        );
      }
    }
    getUseEffectData();
  }, [props.uniqueId, navigate, props.lots, props.projet]);

  const updateTravaux = async (body) => {
    try {
      await putData(
        `/v1/projets/${props.projet}/travaux/${props.uniqueId}`,
        body
      );
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleSelectLot = async (id, value) => {
    try {
      if (value === true) {
        await postData(
          `/v1/projets/${props.projet}/travaux/${props.uniqueId}/lots`,
          {
            projetLotUniqueId: id,
          }
        );

        const travail = await getData(
          `/v1/projets/${props.projet}/travaux/${props.uniqueId}`
        );
        setProjetTravauxLots(
          travail.ProjetTravauxLots.map((projetTravauxLot) => {
            return projetTravauxLot.projetLotUniqueId;
          })
        );
        setProjetLots([]);
        setProjetLots(props.lots);
      } else {
        await deleteData(
          `/v1/projets/${props.projet}/travaux/${props.uniqueId}/lots/${id}`
        );

        const travail = await getData(
          `/v1/projets/${props.projet}/travaux/${props.uniqueId}`
        );
        setProjetTravauxLots(
          travail.ProjetTravauxLots.map((projetTravauxLot) => {
            return projetTravauxLot.projetLotUniqueId;
          })
        );
        setProjetLots([]);
        setProjetLots(props.lots);
      }
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const handleModification = async (type, value) => {
    try {
      switch (type) {
        case "typeTravaux":
          await updateTravaux({
            typeTravaux: value !== "" ? arrondi(value, 10) : null,
          });
          setTypeTravaux(value);
          break;
        case "tauxTVA":
          await updateTravaux({
            tauxTVA: value !== "" ? arrondi(value, 10) : null,
          });
          setTauxTVA(value);
          break;
        case "prixHorsTaxe":
          await updateTravaux({
            prixHorsTaxe: value !== "" ? arrondi(value, 10) : null,
          });
          setPrixHorsTaxe(value !== "" ? arrondi(value, 10) : "");
          break;
        default:
          toast.error(`Unmanaged type ${type}`, toastConfig);
      }
    } catch (error) {
      console.log(JSON.parse(error).error.status === 403);

      if (JSON.parse(error).error.status === 403) {
        navigate(`/`);
      }
      toast.error(
        JSON.parse(error).error.data.message
          ? JSON.parse(error).error.data.message
          : "unknown error",
        toastConfig
      );
    }
  };

  const lots = projetLots.map((projetLot, index) => {
    return (
      <FormGroup key={index} check inline className="mt-6">
        <Label check>Lot n°{projetLot.itemOrder}</Label>
        <Input
          type="checkbox"
          checked={projetTravauxLots.includes(projetLot.uniqueId)}
          onChange={(e) =>
            handleSelectLot(projetLot.uniqueId, e.target.checked)
          }
        />
      </FormGroup>
    );
  });

  const summary = (
    <Row>
      <Col xs="12" className="travaux-summary">
        <span className="mr-10">{refTypeTravaux[typeTravaux]}</span>
        <span className="mr-10">
          {prixHorsTaxe && prixHorsTaxe !== ""
            ? `${prettyEuro(prixHorsTaxe)}`
            : ""}
        </span>
        <span className="mr-10">
          {tauxTVA !== "" && tauxTVA !== null && tauxTVA >= 0
            ? `${refTauxTVA[tauxTVA]}`
            : ""}
        </span>
        <span className="mr-10">
          {prixHorsTaxe !== "" &&
          tauxTVA !== "" &&
          tauxTVA &&
          prixHorsTaxe > 0 &&
          tauxTVA >= 0
            ? `${prettyEuro(
                arrondi(prixHorsTaxe * refTauxTVAValue[tauxTVA], 10)
              )}`
            : ""}
        </span>
        <span className="mr-10">
          {prixHorsTaxe !== "" &&
          tauxTVA !== "" &&
          tauxTVA &&
          prixHorsTaxe > 0 &&
          tauxTVA >= 0
            ? `${prettyEuro(
                arrondi(
                  prixHorsTaxe * refTauxTVAValue[tauxTVA] + prixHorsTaxe,
                  10
                )
              )}`
            : ""}
        </span>
      </Col>
    </Row>
  );
  return (
    <div className="mb-25">
      <Container fluid>
        <Row>
          <Col xs={10}>
            <span className="travaux-nombre">{itemOrder}</span>
            <BsTrash
              color="red"
              size={20}
              className="pointer"
              onClick={props.removeTravaux}
            />
          </Col>
          <Col xs={1} className="travaux-trash"></Col>
        </Row>
        <Row className="pt-10">
          <Col xs={12}>
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "type" ? "active" : ""}
                  onClick={() => setActiveTab("type")}
                >
                  Type
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "calcul" ? "active" : ""}
                  onClick={() => setActiveTab("calcul")}
                >
                  Calcul
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="type" className="travaux-tab">
                <Row>
                  <Col xs="12">
                    <span style={{ display: "inline-flex" }}>
                      <TypeTravaux
                        value={typeTravaux >= 0 ? typeTravaux : ""}
                        onChange={(e) =>
                          handleModification("typeTravaux", e.target.value)
                        }
                      />

                      <FormGroup inline className="ml-10">
                        <Label>Lot(s)</Label>
                        <br />
                        {lots}
                      </FormGroup>
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
              <TabPane tabId="calcul" className="travaux-tab">
                <Row>
                  <Col xs="12">
                    <span style={{ display: "inline-flex" }}>
                      <PrixHorsTaxe
                        value={prixHorsTaxe ? prixHorsTaxe : ""}
                        onChange={(e) =>
                          handleModification("prixHorsTaxe", e.target.value)
                        }
                      />
                      <TauxTVA
                        tauxtvaclassname="ml-10"
                        value={tauxTVA && tauxTVA >= 0 ? tauxTVA : ""}
                        onChange={(e) =>
                          handleModification("tauxTVA", e.target.value)
                        }
                      />
                      <MontantTVA
                        montanttvaclassname="ml-10"
                        value={
                          prixHorsTaxe &&
                          tauxTVA &&
                          prixHorsTaxe > 0 &&
                          tauxTVA >= 0
                            ? arrondi(
                                prixHorsTaxe * refTauxTVAValue[tauxTVA],
                                10
                              )
                            : ""
                        }
                        disabled
                      />
                      <PrixTTC
                        montanttvaclassname="ml-10"
                        value={
                          prixHorsTaxe &&
                          tauxTVA &&
                          prixHorsTaxe > 0 &&
                          tauxTVA >= 0
                            ? arrondi(
                                prixHorsTaxe * refTauxTVAValue[tauxTVA] +
                                  prixHorsTaxe,
                                10
                              )
                            : ""
                        }
                        disabled
                      />
                    </span>
                  </Col>
                </Row>
                {summary}
              </TabPane>
            </TabContent>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Travaux;
