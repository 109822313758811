// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-icon {
  position: relative;
}

.input-icon > i {
  position: absolute;
  display: block;
  transform: translate(0, -50%);
  top: 50%;
  pointer-events: none;
  width: 35px;
  text-align: center;
  font-style: normal;
}

.input-icon > input {
  padding-left: 15px;
  padding-right: 0;
}

.input-icon-right > i {
  right: 0;
  font-size: 10px;
}

.input-icon-right > input {
  padding-left: 0;
  padding-right: 25px;
  text-align: right;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Components/PrixMetreCarre.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,cAAc;EACd,6BAA6B;EAC7B,QAAQ;EACR,oBAAoB;EACpB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,QAAQ;EACR,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB","sourcesContent":[".input-icon {\r\n  position: relative;\r\n}\r\n\r\n.input-icon > i {\r\n  position: absolute;\r\n  display: block;\r\n  transform: translate(0, -50%);\r\n  top: 50%;\r\n  pointer-events: none;\r\n  width: 35px;\r\n  text-align: center;\r\n  font-style: normal;\r\n}\r\n\r\n.input-icon > input {\r\n  padding-left: 15px;\r\n  padding-right: 0;\r\n}\r\n\r\n.input-icon-right > i {\r\n  right: 0;\r\n  font-size: 10px;\r\n}\r\n\r\n.input-icon-right > input {\r\n  padding-left: 0;\r\n  padding-right: 25px;\r\n  text-align: right;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
