import React from "react";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBDropdownLink,
  MDBCollapse,
} from "mdb-react-ui-kit";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import { Link, Navigate } from "react-router-dom";
import { postData, getData } from "../../utils";
import headerLogo from "../../images/header-logo-trasnparent-dark-bg.png";
import { toast } from "react-toastify";
import { MdHelpOutline } from "react-icons/md";

function Navbar(props) {
  const [showBasic, setShowBasic] = React.useState(false);

  const [toLogin, setToLogin] = React.useState(false);
  const [toProjet, setToProjet] = React.useState(false);
  const [newProjet, setNewProjet] = React.useState("");
  const [modalSupportOpen, setModalSupportOpen] = React.useState(false);

  if (toLogin === true) {
    return <Navigate to="/" />;
  }

  if (toProjet === true) {
    return <Navigate to={`/projets/${newProjet}`} />;
  }

  const disconnect = () => {
    localStorage.clear();
    setToLogin(true);
  };

  const createNewProjet = async () => {
    try {
      const abonnement = await getData(`/v1/me/abonnement`);
      if (abonnement.code === 402) {
        toast.warn(`Vous n'avez pas d'abonnement actif`);
      } else {
        const projet = await postData(`/v1/projets`, {
          libelle: "Nouveau Projet",
        });
        setNewProjet(projet.uniqueId);
        setToProjet(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <MDBNavbar expand="lg" light bgColor="light" className="navbar-color">
      <MDBContainer fluid>
        <MDBNavbarBrand href="/dashboard">
          <img alt="logo" height={50} src={headerLogo} />
        </MDBNavbarBrand>

        {props.isLoggedIn === true && (
          <MDBNavbarToggler
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setShowBasic(!showBasic)}
          >
            <MDBIcon icon="bars" fas />
          </MDBNavbarToggler>
        )}
        {props.isLoggedIn === true && (
          <MDBCollapse navbar show={showBasic}>
            <MDBNavbarNav right fullWidth={false} className="mb-2 mb-lg-0">
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link" role="button">
                    <MdHelpOutline />
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem link>
                      <a
                        target="_new"
                        className="support-link"
                        href="mailto:contact@viedemarchanddebiens.fr?subject=Demande de support"
                      >
                        Contacter le support
                      </a>
                    </MDBDropdownItem>
                    <MDBDropdownItem link>
                      <a
                        target="_new"
                        className="support-link"
                        href="mailto:contact@viedemarchanddebiens.fr?subject=Signaler un bug"
                      >
                        Signaler un bug
                      </a>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>

              <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  tag={Link}
                  to="/dashboard"
                >
                  Tableau de bord
                </MDBNavbarLink>
              </MDBNavbarItem>
              {props.isAdmin === true && (
                <MDBNavbarItem>
                  <MDBNavbarLink
                    active
                    aria-current="page"
                    tag={Link}
                    to="/administration"
                  >
                    Administration
                  </MDBNavbarLink>
                </MDBNavbarItem>
              )}
              <MDBNavbarItem>
                <MDBDropdown>
                  <MDBDropdownToggle tag="a" className="nav-link">
                    Calculateur
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem>
                      <MDBDropdownLink>
                        <span onClick={() => createNewProjet()}>
                          Calculer un projet
                        </span>
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownLink tag={Link} to="/fraisnotaire">
                        Calculer frais notaire
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                    <MDBDropdownItem>
                      <MDBDropdownLink tag={Link} to="/fraisenchere">
                        Calculer frais enchere
                      </MDBDropdownLink>
                    </MDBDropdownItem>
                  </MDBDropdownMenu>
                </MDBDropdown>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  tag={Link}
                  to="/compte"
                >
                  Compte
                </MDBNavbarLink>
              </MDBNavbarItem>
              <MDBNavbarItem>
                <MDBNavbarLink
                  active
                  aria-current="page"
                  href="#"
                  onClick={disconnect}
                >
                  Déconnexion
                </MDBNavbarLink>
              </MDBNavbarItem>
            </MDBNavbarNav>
          </MDBCollapse>
        )}
      </MDBContainer>
    </MDBNavbar>
  );
}

export default Navbar;
